import packageInfo from '../../package.json';

export default function Footer() {
  return (
    <footer>
      <div className="container mx-auto mt-24 py-6 px-6 sm:px-0">

        <ul>
          <li>
            <h3>Tools</h3>
          </li>
          <li>
            <div className="flex items-center">
              <a href="/numeri-liberi"><p>Numeri Liberi</p></a>
              <div className="new-tool-badge">new</div>
            </div>
            <div className="flex items-center">
              <a href="/interesse-composto"><p>Interesse composto</p></a>
              <div className="new-tool-badge">new</div>
            </div>
            <div className="flex items-center">
              <a href="/calcolo-rate-mutuo"><p>CalcoloRateMutuo</p></a>
              <div className="new-tool-badge">new</div>
            </div>
            <div className="flex items-center">
              <a href="/json2yaml"><p>Json2Yaml</p></a>
              <div className="new-tool-badge">new</div>
            </div>
          </li>
          <li className="mt-9">
            <div className="bg-[#D8D8D8] h-[1px] w-full"></div>
          </li>
          <li className="mt-9">
            <p>© Just Tools is engineered by <a href="#" className="text-[#1789FC]">Lorenzo Acciarri</a>. v. {packageInfo.version}</p>
          </li>
        </ul>
      </div>
    </footer>
  );
}