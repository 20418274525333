import { a, useTrail } from '@react-spring/web'
import React from 'react'
import styles from './styles.module.css'
type TrailConfiguration = {
  config?: Configuration
  height?: number
  x?: number
  opacity?: number
  from?: { opacity: number, x: number, height: number }
}

type Configuration = { mass: number, tension: number, friction: number }
const configDefault: Configuration = { mass: 5, tension: 2000, friction: 400 }
export const Trail: React.FC<{ open: boolean, config?: TrailConfiguration, children: any }> = ({ open, config, children }) => {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    config: config?.config || configDefault,
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? (config?.height !== undefined ? config.height : 110) : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className={styles.trailsText} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}