import { FormControl, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";

export default function CalcoloRateMutuo() {
  const [investimentoIniziale, setInvestimentoIniziale] = useState<number>(100000)
  const [tassoDinteresseAnnuale, setTassoDinteresseAnnuale] = useState<number>(1.50)
  const [anniMutuo, setAnniMutuo] = useState<number>(30)
  const [tipologiaPagamenti, setTipologiaPagamenti] = useState<'mensile' | 'trimestrale' | 'semestrale' | 'annuale'>('mensile');
  /* const [ripeti, setRipeti] = useState<boolean>(true) */
  // const calcolaMutuo = useCallback(() => {
  //   let accumulatore = 0
  //   for (let i = 1; i <= anniMutuo; i++) {
  //     accumulatore = accumulatore + (1 + (tassoDinteresseAnnuale / 100)) ** i
  //   }
  //   const importoFineale = (investimentoIniziale * (1 + (tassoDinteresseAnnuale / 100)) ** anniMutuo) +
  //     // (investimentoAnnualeRicorrente * (((1 + (tassoDinteresseAnnuale / 100)) ** anniMutuo) - 1) / tassoDinteresseAnnuale / 100)
  //     // investimentoAnnualeRicorrente * accumulatore
  //     setCapitaleFinale(parseFloat(importoFineale.toFixed(2)))
  // }, [anniMutuo, investimentoIniziale, tassoDinteresseAnnuale])
  return (
    <section className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-around">
        <div className="md:w-[50%] w-full flex flex-col text-start mr-4">
          <h2>Calcolo Rate Mutuo</h2>
          <p className="mt-2 !text-[14px]">
            Benvenuto su Just Tools: Interesse Composto

            Sei curioso di scoprire come il denaro cresce nel tempo? Hai mai sentito parlare di "Interesse Composto"? Se la risposta è no, sei nel posto giusto! In questa sezione di Just Tools, esploreremo insieme il potente concetto dell'interesse composto e come può influenzare il tuo capitale nel lungo termine.

            Cos'è l'Interesse Composto?
            L'interesse composto è una forza magica che permette al tuo denaro di guadagnare interessi su interessi nel corso del tempo. A differenza dell'interesse semplice, in cui gli interessi sono calcolati solo sul capitale iniziale, l'interesse composto tiene conto anche degli interessi precedentemente guadagnati. Questo significa che, con il passare del tempo, il tuo capitale crescerà in modo esponenziale.

            Come Funziona?
            Immagina di investire una somma iniziale di denaro. Alla fine di ogni periodo, gli interessi vengono calcolati non solo sul tuo investimento iniziale, ma anche sugli interessi accumulati fino a quel momento. Questo processo si ripete ad ogni periodo successivo, creando una crescita a spirale che può portare a rendimenti significativi nel lungo termine.

            Calcolatore di Interesse Composto Just Tools
            Per rendere tutto più semplice, abbiamo creato il nostro Calcolatore di Interesse Composto Just Tools. Basta inserire l'importo iniziale, il tasso di interesse e il periodo di investimento, e il nostro strumento farà il resto. Potrai visualizzare come il tuo denaro cresce nel tempo grazie all'interesse composto.

            Perché è Importante?
            Capire l'interesse composto è fondamentale per prendere decisioni finanziarie informate. Può influenzare le tue scelte di investimento, aiutandoti a pianificare il futuro in modo più efficace e massimizzare il rendimento del tuo denaro nel tempo.

            Inizia ad esplorare il mondo dell'interesse composto con Just Tools e scopri come questo concetto può fare la differenza nella tua gestione finanziaria. Se hai domande o hai bisogno di assistenza, non esitare a contattarci. Siamo qui per aiutarti a fare scelte finanziarie più consapevoli e informate.

            Buon esplorazione!
          </p>
        </div>
        <div className="md:w-[50%] w-full mt-4 md:mt-0 md:ml-4 p-0 md:p-10 h-fit">
          <div className="common-card p-10 h-fit">
            <TextField size="small" InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} onChange={(e) => { setInvestimentoIniziale(parseInt(e.target.value)) }} value={investimentoIniziale} type="number" className="w-full" label="Importo del mutuo:" variant="outlined" />
            <TextField size="small" onChange={(e) => { setAnniMutuo(parseInt(e.target.value)) }} value={anniMutuo} type="number" className="w-full !mt-8" label="Anni di mutuo:" variant="outlined" />
            <TextField size="small" onChange={(e) => { setTassoDinteresseAnnuale(parseInt(e.target.value)) }} value={tassoDinteresseAnnuale} type="number" className="w-full !mt-8" label="Tasso Di interesse Annuale:" variant="outlined" />
            {/* <FormControlLabel label="Tipologia pagamento:" /> */}
            <FormControl size="small" variant="outlined" className="w-full !mt-8">
              <Select value={tipologiaPagamenti} onChange={(e: any) => { setTipologiaPagamenti(e.target.value) }}>
                <MenuItem value="mensile">Mensile</MenuItem>
                <MenuItem value="trimestrale">Trimestrale</MenuItem>
                <MenuItem value="semestrale">Semestrale</MenuItem>
                <MenuItem value="annuale">Annuale</MenuItem>
              </Select>
            </FormControl>
            <button onClick={() => {
              //calcolaMutuo()
            }} className="button-variant-1 mt-6 !w-full">Calcola Rate del mutuo</button>
            {/* {capitaleFinale &&
              <div className="flex lg:flex-row flex-col mt-5">
                <h4 className="lg:mt-0 mt-3">Capitale Finale: {new Intl.NumberFormat('it-IT', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(capitaleFinale)}</h4>
                <h4 className="lg:mt-0 mt-3">Capitale Investito: {new Intl.NumberFormat('it-IT', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(investimentoIniziale + (anniMutuo * investimentoAnnualeRicorrente))}</h4>
                <h4 className="lg:mt-0 mt-3">Interessi Maturati: {new Intl.NumberFormat('it-IT', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(capitaleFinale - (investimentoIniziale + (anniMutuo * investimentoAnnualeRicorrente)))}</h4>
              </div>
            } */}
          </div>
          <div className="mt-7">
            <h3>Legenda campi</h3>
            <ul className="mt-3">
              <li >
                <strong>Capitale Iniziale:</strong> capitale investito all'inizio del periodo di investimento;
              </li>
              <li >
                <strong>Capitale Investito Ricorrente:</strong> capitale aggiunto ogni anno al capitale iniziale durante il periodo di investimento;
              </li>
              <li >
                <strong>Anni di investimento:</strong> il periodo di tempo durante il quale si desidera investire il capitale iniziale e il capitale aggiunto ogni anno;
              </li>
              <li >
                <strong>Tasso Di interesse Annuale:</strong> il tasso di interesse medio stimato (Il tasso di interesse medio sul mercato azionario è del 7%);
              </li>
              <li >
                <strong>Capitale Finale:</strong> il totale del capitale iniziale e di tutte le aggiunte effettuate durante il periodo di investimento, compresi gli interessi maturati;
              </li>
              <li >
                <strong>Capitale Investito:</strong> il totale del capitale iniziale e di tutte le aggiunte effettuate durante il periodo di investimento, esclusi gli interessi maturati;
              </li>
              <li >
                <strong>Interessi Maturati:</strong> il totale degli interessi maturati durante tutto il periodo di investimento sul capitale iniziale e sulle aggiunte ricorrenti.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}