import { Link } from "react-router-dom";
import { Trail } from "./animations/animation";

export default function Home() {

  return (
    <section className="flex flex-col">
      <div className="flex justify-around">
        <div className="flex flex-col w-[50%] justify-center mr-4">
          <Trail open={true}>
            <span><h1 className="text-start">Just Tools</h1></span>
            <span><h1 className="text-start">Semplifica la Tua Quotidianità</h1></span>
          </Trail>
          <div className="mt-8 w-[70%] text-start"></div>
          {/*           <Trail config={{
            height: 20
          }} open={true}>
            <span> */}
          <p>Dai un tocco di semplicità alla tua giornata con una vasta gamma di strumenti pratici,</p>
          {/*  </span>
            <span> */}
          <p>dal generatore di numeri casuali alla gestione delle spese e oltre.</p>
          {/*  </span>
          </Trail> */}
          <a href="#tools">
            <button className="button-variant-1 mt-6">Scopri di più</button>
          </a>
          {/* <button className="button-variant-2 mt-4">Action 2</button> */}
        </div>
        <div className="w-[50%] flex justify-center ml-4">
          <img className="w-full" src={`${process.env.PUBLIC_URL}/home-image 1.svg`} alt="Milanofiori-tagline-Main-logo-H-N" />
        </div>
      </div>
      <div id="tools" className="flex mt-24">
        <ul className="tool-wrapper grid grid-cols-2 xl:grid-cols-4 gap-4">
          <li >
            <Link className="tool-card" to="/numeri-liberi">
              <h3>Numeri Liberi</h3>
              <p>Libera la tua mente con numeri casuali - ideale per giochi, lotterie e decisioni istantanee.</p>
            </Link>
          </li>
          <li>
            <Link className="tool-card" to="/interesse-composto">
              <h3>Interesse composto</h3>
              <p>Calcolatore interesse composto: massimizza guadagni nel tempo, fondamentale per investimenti intelligenti e crescita finanziaria a lungo termine.</p>
            </Link>
          </li>
          <li>
            <Link className="tool-card" to="/json2yaml">
              <h3>Json2Yaml</h3>
              <p>Convertitore JSON a YAML: semplifica la trasformazione di dati strutturati.</p>
            </Link>
          </li>
          <li >
            <Link className="tool-card" to="/numeri-liberi">
              <h3>Lorem, ipsum dolor.</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}