/**
 * @author Lorenzo Acciarri - JEF
 * @email lorenzo.acciarri@jef.it
 * @create date 2024-03-07 14:40:50
 * @modify date 2024-03-07 14:40:50
 * @desc Strumento per convertire un json in yaml
 */

import { useCallback, useMemo, useState } from "react";
var YAML = require('json2yaml')
var beautify = require("json-beautify");
const obj = {
    name: 'Jhon',
    surname: 'Doe',
    age: 30,
    address: {
        street: 'Main St',
        number: '123',
        city: 'Springfield'
    }

};
export default function Json2Yaml() {
    console.log(beautify(obj)); // foo: Hello World!
    const [json, setJson] = useState<any>(obj)
    const yaml = useMemo(() => {
        return YAML.stringify(json)
    }, [json])
    const prettyJson = beautify(json, null, 4)
    const jsonChanged = useCallback((e: string) => {
        try {
            const obj = JSON.parse(e)
            setJson(obj)
        } catch (error) {
            /* Nel caso in cui il JSON non è valido mantengo quello precedente */
        }
    }, [])
    return (
        <section className="flex flex-col">
            <h2>Json2Yaml</h2>
            <div className="w-full">
                <div className="flex">
                    <div className="w-[50%] min-h-[30vh] !mr-4 flex flex-col">
                        <strong>Json</strong>
                        <pre className="json-input" contentEditable="true" onPaste={(e) => {
                            jsonChanged(e.clipboardData.getData('Text'))
                        }} onInput={(e) => {
                            jsonChanged(e.currentTarget.textContent || '')
                        }}>{prettyJson}</pre>
                    </div>
                    <div className="w-[50%] min-h-[30vh] !mr-4 flex flex-col">
                        <strong>YAML</strong>
                        <pre className="json-input" >{yaml}</pre>
                    </div>
                </div>
            </div>
        </section>
    )
}