import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useState } from "react";

export default function NumeriRandom() {
  const [da, setDa] = useState<number>(0)
  const [a, setA] = useState<number>(10)
  const [ripeti, setRipeti] = useState<boolean>(true)
  const [generatedNumbers, setGeneratedNumbers] = useState<number[]>([])
  return (
    <section className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-around">
        <div className="md:w-[50%] w-full flex flex-col text-start mr-4">
          <h2>Numeri Liberi - Libera la Tua Mente con Numeri Casuali</h2>
          <p className="mt-2 !text-[14px]">
            Esplora il potere dell'imprevedibilità con Numeri Liberi, il tuo compagno per decisioni senza sforzo, giochi intriganti e momenti di fortuna. Questo strumento avanzato di generazione di numeri casuali è progettato per liberare la tua mente dagli oneri delle scelte quotidiane.
          </p>
          <p className="mt-2 !text-[14px]">
            <i>Ideale per Giochi</i>: Sia che tu stia pianificando una serata di giochi da tavolo o stia cercando numeri fortunati per la tua prossima partita, Numeri Liberi è il compagno perfetto. Genera sequenze casuali che aggiungono un tocco di mistero e eccitazione ai tuoi momenti ludici.
          </p>
          <p className="mt-2 !text-[14px]">
            <i>Per Lotterie</i>: Sogni di fortuna? Numeri Liberi può essere il tuo alleato nella ricerca della combinazione vincente. Lascia che la casualità guidi la scelta dei tuoi numeri e aumenta l'emozione di ogni estrazione.
          </p>
          <p className="mt-2 !text-[14px]">
            <i>Decisioni Istantanee</i>: Affronta le decisioni quotidiane con facilità. Quando le opzioni sembrano infinite, Numeri Liberi offre una soluzione rapida e priva di preoccupazioni. Dall'ordine dei tuoi piatti preferiti a quale film guardare, lascia che la casualità guidi la tua scelta.
          </p>
          <p className="mt-2 !text-[14px]">
            Con Numeri Liberi, abbraccia il fascino dell'inaspettato e libera la tua mente da complicazioni, rendendo ogni momento un'opportunità di scoperta e divertimento.
          </p>
        </div>
        <div className="md:w-[50%] w-full mt-4 md:mt-0 md:ml-4 p-0 md:p-10 h-fit">
          <div className="common-card p-10 h-fit">

            <TextField size="small" onChange={(e) => { setDa(parseInt(e.target.value)) }} value={da} type="number" className="w-full" label="Da:" variant="outlined" />
            <TextField size="small" onChange={(e) => { setA(parseInt(e.target.value)) }} value={a} type="number" className="w-full !mt-8" label="A:" variant="outlined" />
            <FormControlLabel className="w-full" control={<Checkbox checked={ripeti} onChange={(e) => {
              setRipeti(e.target.checked)
              setGeneratedNumbers([])
            }} />} label="Ripeti numeri già usciti" />
            <button onClick={() => {
              const oldNumbers = generatedNumbers
              let newNumber = Math.floor(Math.random() * (a - da + 1)) + da;
              if (!ripeti) {
                while (generatedNumbers.includes(newNumber)) {
                  newNumber = Math.floor(Math.random() * (a - da + 1)) + da;
                }
              }
              setGeneratedNumbers([...oldNumbers, newNumber])
            }} className="button-variant-1 mt-6 !w-full">Genera</button>
            <button className="button-variant-2 mt-4 !w-full">Cancella</button>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-20">
        <h3>Ultimi Numeri</h3>
        {generatedNumbers.length === 0 && <p>Non ci sono numeri generati</p>}
        <div className="grid grid-cols-12 gap-5">
          {(generatedNumbers.reverse()).map((number, index) => {
            return (
              <div key={index} className={`${index > 0 ? '' : ''} flex`}>
                <strong className="text-[#A5B7C6]">{index + 1}°</strong>
                <strong className="underline ml-3">{number}</strong>
              </div>
            )
          })}
        </div>
      </div>
    </section >
  )
}