import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import NumeriLiberi from './components/NumeriLiberi';
import InteresseComposto from './components/InteresseComposto';
import Json2Yaml from './components/Json2Yaml';
import CalcoloRateMutuo from './components/CalcoloRateMutuo';

function App() {
  return (
    <>
      <Header />
      <div className="container mx-auto mt-12 px-6 sm:px-0">

        <BrowserRouter>
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/numeri-liberi" element={<NumeriLiberi />} />
            <Route path="/interesse-composto" element={<InteresseComposto />} />
            <Route path="/json2yaml" element={<Json2Yaml />} />
            <Route path="/calcolo-rate-mutuo" element={<CalcoloRateMutuo />} />

          </Routes>
        </BrowserRouter>
      </div>
      <Footer/>
    </>

  )
}

export default App;
